import { Ref, computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import BillingPlanSelectionModalContent from '../components/BillingPlanSelectionModalContent/index.vue';
import { useChangePlanSelectionStep } from './useChangePlanSelectionStep';
import { EBillingPlanSelectionSteps } from '../domain/constants';

export const useView = (
  billingContentRef: Ref<InstanceType<typeof BillingPlanSelectionModalContent> | null>,
) => {
  const {
    step,
    isStepPlanSelection,
    isStepChangeTenantInfo,
    isStepCompleteConnection,

    changePlanSelectionStep,
  } = useChangePlanSelectionStep();

  const isBillingCarrierStartPlanAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingCarrierStartPlan),
  );

  const submitButtonTitle = computed(() => (
    billingContentRef.value?.planCode === EBillingPlanCode.cargoOwner
      ? tt('billing.submitApplication')
      : tt('billing.connectPlan')),
  );

  const modalTitle = computed(() => (
    isStepPlanSelection
      ? tt('billing.choosePlan.long')
      : tt('billing.planConnection')),
  );

  const modalWidth = computed(() => (
    isBillingCarrierStartPlanAvailable.value
    && billingContentRef.value?.currentStep === EBillingPlanSelectionSteps.planSelection
      ? 940 : 640
  ));

  return {
    step,
    submitButtonTitle,
    modalTitle,
    isStepChangeTenantInfo,
    isStepCompleteConnection,
    modalWidth,

    changePlanSelectionStep,
  };
};
