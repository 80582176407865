import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { TDigitalQueueGates } from '@/contexts/contractorsContext/domain/types';
import { TTableColumn } from '@/types';
import { EDigitalQueuePageType, EDigitalQueuePageView } from '@/contexts/digitalQueuesContext/domain/constants';
import { TDigitalQueuePermissions } from '@/contexts/digitalQueuesContext/domain/types';

import type {
  Nullable,
  TDigitalQueueSettingsStore,
  TGateSettings,
  TColumnsSettings,
} from './types';

export const useDigitalQueueSettingsStore = defineStore(
  'digitalQueueSettings',
  (): TDigitalQueueSettingsStore => {
    const currentDigitalQueueId = ref<string>('');
    const openedGates = ref<TGateSettings[]>([]);
    const currentGates = ref<TDigitalQueueGates[]>([]);
    const pageType = ref<EDigitalQueuePageType>(EDigitalQueuePageType.booking);
    const viewMode = ref<EDigitalQueuePageView>(EDigitalQueuePageView.timeline);
    const permissions = ref<TDigitalQueuePermissions | null>(null);
    const columnsSettings = ref<TColumnsSettings[]>([]);

    const $reset = () => {
      currentDigitalQueueId.value = '';
      openedGates.value = [];
      currentGates.value = [];
      pageType.value = EDigitalQueuePageType.booking;
      viewMode.value = EDigitalQueuePageView.timeline;
      permissions.value = null;
    };

    const setDigitalQueueId = (id: Nullable<string>) => {
      currentDigitalQueueId.value = id || '';
    };

    const setOpenedGates = (gates: TGateSettings[]) => {
      openedGates.value = gates;
    };

    const setCurrentGates = (gates: TDigitalQueueGates[]) => {
      currentGates.value = gates;
    };

    const setPageType = (newPageType: EDigitalQueuePageType) => {
      pageType.value = newPageType;
    };

    const setViewMode = (newViewMode: EDigitalQueuePageView) => {
      viewMode.value = newViewMode;
    };

    const setGateSlotDuration = (payload: TGateSettings) => {
      openedGates.value
        .find((gate: TGateSettings) => payload.id === gate.id)!.slotDuration = payload.slotDuration;
    };

    const setPermissions = (payload: TDigitalQueuePermissions | null) => {
      permissions.value = payload;
    };

    const setColumnsSettings = (digitalQueueId: string, payload: TTableColumn[]) => {
      const foundDigitalQueue = columnsSettings.value.find((item: TColumnsSettings) => item.id === digitalQueueId);

      if (foundDigitalQueue) {
        const mergeMap = new Map(foundDigitalQueue.columnsSettings.map((item: TTableColumn) => [item.slug, item]));

        payload.forEach((item: TTableColumn) => {
          mergeMap.set(item.slug, item);
        });

        const newColumnSettings = Array.from(mergeMap.values());
        foundDigitalQueue.columnsSettings = newColumnSettings;
      } else {
        columnsSettings.value.push({
          id: digitalQueueId,
          columnsSettings: payload,
        });
      }
    };

    return {
      currentDigitalQueueId,
      openedGates,
      currentGates,
      pageType,
      viewMode,
      permissions,
      columnsSettings,

      setDigitalQueueId,
      setOpenedGates,
      setCurrentGates,
      setPageType,
      setViewMode,
      setGateSlotDuration,
      setPermissions,
      setColumnsSettings,

      $reset,
    };
  },
  { persist: true },
);
