<template>
  <div
    class="billing-plan-selection-card"
  >
    <div class="billing-plan-selection-card__info">
      <p class="billing-plan-selection-card__info-title">
        {{ title }}
      </p>
      <p class="billing-plan-selection-card__info-subtitle">
        {{ subtitle }}
      </p>
      <p
        v-if="price === 0"
        class="billing-plan-selection-card__info-price"
      >
        <span>{{ tt('billing.free') }}*</span>
      </p>
      <p
        v-else-if="!withoutPrice"
        class="billing-plan-selection-card__info-price"
      >
        <span>
          {{ `${formatNumberAsCurrency(Number(price), 0, {
            separator: ' ',
            symbol: getCurrencySymbol(currency),
            pattern: '# !'
          })}` }}
        </span>
        / {{ tt('shared.shortMonth') }}
      </p>
      <div class="billing-plan-selection-card__info-button-block">
        <div
          v-if="isActivePlan"
          class="billing-plan-selection-card__info-current-plan-block"
        >
          <CheckSvg />
          {{ tt('billing.modal.yourCurrentPlan') }}
        </div>
        <Button
          v-else
          class="billing-plan-selection-card__info-button"
          type="primary"
          @click="$emit('change', planCode)"
        >
          {{ actionText }}
        </Button>
        <p
          class="billing-plan-selection-card__info-trial-period"
        >
          {{ billingTrialPeriodInfoText }}
        </p>
      </div>
    </div>
    <div class="billing-plan-selection-card__divider" />
    <div class="billing-plan-selection-card__description">
      <ul>
        <li
          v-if="forExtended"
          class="billing-plan-selection-card__description-extended"
        >
          {{ tt('billing.modal.everythingInTheBasic') }} +
        </li>
        <slot name="description" />
      </ul>
    </div>
  </div>
</template>
<script lang="ts">

import { Button } from 'ant-design-vue';
import {
  PropType,
  defineComponent,
  toRef,
} from 'vue';

import { COUNT_OF_BILLING_TRIAL_PERIOD_DAYS } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode, EBillingPlaneCodePrice } from '@/contexts/billingContext/domain/constants';
import {
  getCurrencySymbol,
  ECurrency,
  formatNumberAsCurrency,
} from '@/utils';
import CheckSvg from '@/assets/svg/16x16/check.svg';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingPlanSelectionCard',
  components: {
    Button,
    CheckSvg,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    currency: {
      type: String as PropType<ECurrency>,
      default: ECurrency.rub,
    },
    price: {
      type: [Number, null] as PropType<EBillingPlaneCodePrice | null>,
      default: null,
    },
    withoutPrice: {
      type: Boolean,
      default: false,
    },
    forExtended: {
      type: Boolean,
      default: false,
    },
    planCode: {
      type: String as PropType<EBillingPlanCode>,
      default: EBillingPlanCode.carrierBasic,
    },
    actionText: {
      type: String,
      default: tt('shared.action.choose'),
    },
    hasInfoAboutTrialPeriod: {
      type: Boolean,
      default: false,
    },
    isActivePlan: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props) {
    const { billingTrialPeriodInfoText } = useView(toRef(props, 'hasInfoAboutTrialPeriod'));
    return {
      billingTrialPeriodInfoText,
      COUNT_OF_BILLING_TRIAL_PERIOD_DAYS,

      getCurrencySymbol,
      formatNumberAsCurrency,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
