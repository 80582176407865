<template>
  <BillingPlanSelectionCard
    :title="tt('billing.planCode.basic')"
    :subtitle="tt('billing.modal.ifYouAreTheSenderOrRecipientOfTheGoods')"
    :planCode="EBillingPlanCode.shipper"
    :isActivePlan="isActivePlan"
    :price="availablePlans[0].price"
    style="width: 100%;"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.trackingAndControlOfVehicleArrivalAtLoadingAndUnloadingPoints') }}</li>
      <li>{{ tt('billing.modal.recordingTheDateAndTimeOfArrivalAndDepartureOfTheVehicle') }}</li>
      <li>{{ tt('billing.modal.creationAndSigningOfEtRN') }}</li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import useStore from '@/store/useStore';
import { TSubscription } from '@/types';
import { TBillingAvailablePlansResponse } from '@/contexts/billingContext/domain/types';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';

export default defineComponent({
  name: 'BillingCardsForShipper',
  components: { BillingPlanSelectionCard },
  props: {
    availablePlans: {
      type: Array as PropType<TBillingAvailablePlansResponse>,
      default: () => [],
    },
  },
  emits: ['change'],
  setup() {
    const store = useStore();

    const currentActiveSubscription = computed(
      () => store.state.tenants.currentTenantInfo?.subscriptions?.find(
        (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active,
      ),
    );

    const isActivePlan = computed(() => currentActiveSubscription.value?.planCode === EBillingPlanCode.shipper);
    return {
      EBillingPlanCode,
      isActivePlan,

      tt,
    };
  },
});
</script>
